
import { NgModule } from '@angular/core';
import { KurzTooltipDirective } from './kurz-tooltip.directive';
@NgModule({
  declarations: [
    KurzTooltipDirective,
  ],
  exports: [
    KurzTooltipDirective,
  ]
})
export class KurzTooltipModule { }
